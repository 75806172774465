import styled from 'styled-components'
import CommonStyle from './CommonStyle.styled'

const NgmNoticeText = styled(CommonStyle)`
  color: #51555d;
  letter-spacing: -0.48px;
  word-break: keep-all;
  text-align: left;
`

export default NgmNoticeText
