import styled from 'styled-components'
import CommonStyle from '../../../common/styled/CommonStyle.styled'

const NgmInstallHeader = styled(CommonStyle)`
  height: 194px;
  width: 452px;
  text-align: center;
  display: block;
`

export default NgmInstallHeader
