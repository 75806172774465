import styled from 'styled-components'

const AgreementWrapper = styled.div<{ $isShow: boolean }>`
  display: ${(props) => (props.$isShow ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  /* padding: 24px; */
`

export default AgreementWrapper
