import styled from 'styled-components'
import CommonStyle from '../../../common/styled/CommonStyle.styled'

const NgmStartContent = styled(CommonStyle)`
  color: #51555d;
  letter-spacing: -0.07px;
  text-align: center;
  line-height: 1.5;
  font-size: 20px;
`

export default NgmStartContent
