import styled from 'styled-components'
import CommonStyle from '../../styled/CommonStyle.styled'

const NgmBtnClose = styled(CommonStyle)`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  border: none;

  img {
    border: none;
  }
`

export default NgmBtnClose
