import { ComponentChildren, createContext } from 'preact'
import { StateUpdater, useState } from 'preact/hooks'
import { getOS } from './utils'

type ContextType = {
  title: string
  os: ReturnType<typeof getOS>
  platform: 'NexonKorea' | 'NexonJapan' | undefined
}
type Dispatch = StateUpdater<ContextType>

const defaultState: ContextType = {
  title: '',
  os: getOS(),
  platform: undefined,
}

export const AppStateContext = createContext<ContextType>(defaultState)
export const AppDispatchContext = createContext<Dispatch>(() => { })

const AppProvider = ({ children }: { children: ComponentChildren }) => {
  const [appState, setAppState] = useState<ContextType>(defaultState)

  return (
    <AppDispatchContext.Provider value={setAppState}>
      <AppStateContext.Provider value={appState}>{children}</AppStateContext.Provider>
    </AppDispatchContext.Provider>
  )
}

export default AppProvider
