import styled from 'styled-components'
import CommonStyle from './common/styled/CommonStyle.styled'

const NgmInstallLayer = styled(CommonStyle)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: transparent; */
  z-index: 100000001;

  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;

  /* position: fixed;
  background-color: #fff;
  left: 50%;
  top: 50%;
  @media (max-height: 650px) {
    -webkit-transform: translate(-50%, -48vh);
    transform: translate(-50%, -48vh);
  }
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 24px 24px 0;
  z-index: 100000001;
  box-shadow: 0 3px 25px 0 rgba(0, 0, 0, 0.16);
  border-radius: 8px; */
`

export default NgmInstallLayer
