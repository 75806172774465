import styled from 'styled-components'

const AgreementTextbox = styled.div`
  height: 186px;
  background: #f1f1f1;
  border-radius: 4px;
  overflow-y: scroll;

  display: flex;
  align-items: flex-start;
  gap: 14px;
  padding: 16px 8px 20px 20px;

  text {
    color: #747a86;
    letter-spacing: -0.3px;
    font-size: 14px;
    white-space: pre-line;
  }

  &::-webkit-scrollbar {
    width: 24px;
  }

  &::-webkit-scrollbar-thumb {
    background: #d2d6e0;
    height: 160px;

    border: 8px #f1f1f1 solid;
    border-radius: 9999px;
    background-clip: padding-box;
  }
`
export default AgreementTextbox
