import styled from 'styled-components'

const AgreementButton = styled.button`
  height: 48px;
  border: 0px;
  border-radius: 4px;
  margin-bottom: 24px;

  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  letter-spacing: -0.35px;

  color: white;
  background-color: black;
  text-align: center;

  &:disabled {
    color: #8f96a3;
    background-color: #d2d6e0;
  }
`

export default AgreementButton
