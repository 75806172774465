import { FunctionComponent, h } from 'preact'
import { useContext } from 'preact/hooks'
import { tNGM as t } from '../../../common/i18n'
import NgmNoticeImgContainer from '../../common/styled/NgmNoticeImgContainer.styled'
import NgmNoticeNumbering from '../../common/styled/NgmNoticeNumbering.styled'
import NgmNoticeTextBox from '../../common/styled/NgmNoticeTextBox.styled'
import NgmNoticeText from '../GuideFirst/NgmNoticeText.styled'
import Image from '@/components/common/Image/Image'
import NgmNumberingWrapper from '@/components/common/styled/NgmNumberingWrapper.styled'
import { isMac } from '@/common/utils'
import NgmNoticeContainerWrapper from '@/components/common/styled/NgmNoticeContainerWrapper.styled'
import { AppStateContext } from '@/common/context'

const GuideThirdForNgm: FunctionComponent = () => {
  const { platform } = useContext(AppStateContext)
  const isGlobal = false
  const mac = isMac()

  return (
    <NgmNoticeContainerWrapper>
      <NgmNoticeTextBox>
        <NgmNumberingWrapper>
          <NgmNoticeNumbering>3</NgmNoticeNumbering>
        </NgmNumberingWrapper>

        <NgmNoticeText>
          <span dangerouslySetInnerHTML={{ __html: t('notice_desc_3_a') }} />
          &nbsp;
          <span dangerouslySetInnerHTML={{ __html: t('notice_desc_3_b') }} />
          <span dangerouslySetInnerHTML={{ __html: t('notice_desc_3_c') }} />
        </NgmNoticeText>
      </NgmNoticeTextBox>
      <NgmNoticeImgContainer>
        <Image fileName="guide-3" width={mac ? 210 : 169} />
      </NgmNoticeImgContainer>
    </NgmNoticeContainerWrapper>
  )
}

export default GuideThirdForNgm
