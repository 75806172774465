import styled from 'styled-components'
import CommonStyle from '@/components/common/styled/CommonStyle.styled'

const AgreementContainer = styled(CommonStyle)`
  width: 526px;

  display: flex;
  flex-direction: column;
`

export default AgreementContainer
