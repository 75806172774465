import { FunctionComponent, h } from 'preact'
import { useState, useEffect } from 'preact/hooks'
import SectionWait from '../SectionWait/SectionWait'
import SectionDownload from '../SectionDownload/SectionDownload'
import SectionDownloadForNgm from '../SectionDownload/SectionDownloadForNgm'

type SubProps = {
  showGuide: ({ target }: { target: EventTarget | null }) => void
}

const SubForNgm: FunctionComponent<SubProps> = ({ showGuide }) => {
  const [flag, setFlag] = useState(false)

  useEffect(() => {
    if (!flag) {
      const timeoutSet = setTimeout(() => {
        clearTimeout(timeoutSet)
        setFlag(true)
      }, 5 * 1000)
    }
  })

  if (!flag) {
    return <SectionWait />
  }

  if (window.NgmLayer.isNGMDownloadService()) {
    return <SectionDownloadForNgm showGuide={showGuide} />
  }

  return <SectionDownload showGuide={showGuide} />
}

export default SubForNgm
