import styled from 'styled-components'
import CommonStyle from '../../../common/styled/CommonStyle.styled'

const NgmActiveBtn = styled(CommonStyle)`
  background-color: #18191c;
  color: white;
  letter-spacing: -0.48px;
  font-size: 14px;
  border-radius: 3px;
  cursor: pointer;
  padding: 10px 12px;
  line-height: 1.57;
  display: inline-block;
  font-weight: bold;
`

export default NgmActiveBtn
