import { useContext, useEffect, useState } from 'preact/hooks'
import Lottie from 'react-lottie-player'
import { h, FunctionComponent, createRef } from 'preact'
import { getStaticURL } from '../../../common/utils'
import { AppStateContext } from '../../../common/context'
import animationData from '../../../assets/lottie/motion.json'
import NgmGameTitle from './styled/NgmGameTitle.styled'
import NgmInstallHeader from './styled/NgmInstallHeader.styled'
import NgmLoadingImage from './styled/NgmLoadingImage.styled'
import NgmStartContent from './styled/NgmStartContent.styled'
import { t } from '../../../common/i18n'

type LottieRenderComponentPropsType = {
  options: {
    loop: boolean
    autoplay: boolean
    animationData: any
    rendererSettings: {
      preserveAspectRatio: string
      id: string
    }
  }
}

const GIFRenderer = () => <img src={`${getStaticURL()}/assets/imgs/motion.gif`} alt="Loading" />

const LottieRenderComponent = (props: LottieRenderComponentPropsType) => {
  const { options } = props
  const [isAvailableLottie, setIsAvailableLottie] = useState(true)
  const isClientSide = typeof window !== 'undefined'
  const player = createRef()

  useEffect(() => {
    if (player.current) {
      const ele = player.current.wrapper
      const lottieCustomId = '__fesdk_ngm_loading_image'

      const defsEl = ele.querySelector('defs clipPath')
      if (defsEl?.id) {
        setIsAvailableLottie(true)
        defsEl.id = lottieCustomId
      } else {
        setIsAvailableLottie(false)
      }

      const gEl = ele.querySelector('g[clip-path]')?.attributes['clip-path'] || null
      if (gEl && gEl?.textContent) {
        setIsAvailableLottie(true)
        gEl.textContent = `url(#${lottieCustomId})`
      } else {
        setIsAvailableLottie(false)
      }
    }
  }, [])

  return isClientSide && isAvailableLottie ? (
    <Lottie
      ref={player}
      className="lottie_player"
      loop={options.loop}
      play={options.autoplay}
      rendererSettings={options.rendererSettings}
      animationData={options.animationData}
      style={{ width: '160px', height: '80px', margin: '0 auto' }}
    />
  ) : (
    <GIFRenderer />
  )
}

const Main: FunctionComponent = () => {
  const { title } = useContext(AppStateContext)

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      id: 'ngm_animation_lottie_player',
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <NgmInstallHeader data-testid="ngm-install-header">
      <NgmLoadingImage>
        <LottieRenderComponent options={defaultOptions} />
      </NgmLoadingImage>
      <NgmGameTitle>{title || t('common_game_default')}</NgmGameTitle>
      {title && <NgmStartContent>{t('install_title')}</NgmStartContent>}
    </NgmInstallHeader>
  )
}

export default Main
