import styled from 'styled-components'

const CommonStyle: any = styled.div`
  * {
    padding: 0;
    margin: 0;
    line-height: 1.4;
    font-size: 1;
    font-family: Noto Sans KR, 고딕, Gothic;
    color: #17191d;
    font-weight: normal;
    vertical-align: top;
    -webkit-box-sizing: padding-box;
    -moz-box-sizing: padding-box;
    -ms-box-sizing: padding-box;
    -o-box-sizing: padding-box;
    box-sizing: padding-box;
  }
`

export default CommonStyle
