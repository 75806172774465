import styled from 'styled-components'
import NgmNoticeContainer from '@/components/guide/Guide/styled/NgmNoticeContainer.styled'
import CommonStyle from './CommonStyle.styled'

const NgmNoticeImgContainer = styled(CommonStyle)`
  width: 37%;
  text-align: center;

  img {
    filter: drop-shadow(0 6px 7px #d5d5d5);
  }

  ${NgmNoticeContainer}[data-os="mac"] & {
    width: 41%;
  }
`

export default NgmNoticeImgContainer
