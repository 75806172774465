import { FunctionComponent, h } from 'preact'
import NgmBtnClose from './styled/NgmBtnClose.styled'

const CloseButton: FunctionComponent = () => {
  const onClick = () => {
    if (typeof window.NGM !== 'undefined') window.NGM.ClearNGMStatusTimer()
    window.NgmLayer.closeNgmLayer()
  }

  return (
    <NgmBtnClose id="ngmInstallLayerClose" onClick={onClick} aria-hidden="true">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 36 36" fill="none">
        <path d="M27 9L9 27M9 9L27 27" stroke="#18191C" strokeWidth="3" strokeLinecap="square" strokeLinejoin="round" />
      </svg>
    </NgmBtnClose>
  )
}

export default CloseButton
