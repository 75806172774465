import styled from 'styled-components'

const AgreementDescription = styled.div`
  color: #17191c;
  /* font-family: Noto Sans CJK KR; */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  letter-spacing: -0.35px;

  padding-bottom: 16px;
`

export default AgreementDescription
