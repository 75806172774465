import { h } from 'preact'
import { useContext } from 'preact/hooks'
import styled from 'styled-components'
import { t, tNGM } from '../../../common/i18n'
import { AppStateContext } from '../../../common/context'
import GuideFirst from '../GuideFirst/GuideFirst'
import GuideFirstForNgm from '../GuideFirst/GuideFirstForNgm'
import GuideSecond from '../GuideSecond/GuideSecond'
import GuideSecondForNgm from '../GuideSecond/GuideSecondForNgm'
import GuideThird from '../GuideThird/GuideThird'
import GuideThirdForNgm from '../GuideThird/GuideThirdForNgm'
import GuideFourth from '../GuideFourth/GuideFourth'
import GuideFourthForNgm from '../GuideFourth/GuideFourthForNgm'
import StartButton from '../../common/StartButton/StartButton'
import NgmNoticeContainer from './styled/NgmNoticeContainer.styled'
import NgmNoticeHeaderStyle from './styled/NgmNoticeHeaderStyle.styled'
import NgmSpanStyle from './styled/NgmSpanStyle.styled'
import NgmStrongStyle from './styled/NgmStrongStyle.styled'
import GuideDivider from './styled/GuideDivider.styled'

const GuideWrapper = styled.div<{ $isShow: boolean }>`
  display: ${(props) => (props.$isShow ? 'block' : 'none')};
`

const Guide = ({ isShow }: { isShow: boolean }) => {
  const { os, title } = useContext(AppStateContext)

  return (
    <GuideWrapper $isShow={isShow}>
      <NgmNoticeHeaderStyle data-testid="ngm-notice-header">
        <NgmSpanStyle>
          {title && t('notice_welcome_en')}
          <NgmStrongStyle>{title || t('notice_welcome_default')}</NgmStrongStyle>
          {title && t('notice_welcome_kr')}
        </NgmSpanStyle>
      </NgmNoticeHeaderStyle>
      <NgmNoticeContainer os={os} data-os={os}>
        <GuideFirst />
        <GuideDivider />
        <GuideSecond />
        <GuideDivider />
        <GuideThird />
        <GuideDivider />
        <GuideFourth />
      </NgmNoticeContainer>
      <StartButton isShow={isShow} />
    </GuideWrapper>
  )
}

const GuideForNGM = ({ isShow }: { isShow: boolean }) => {
  const { os, title } = useContext(AppStateContext)

  return (
    <GuideWrapper $isShow={isShow}>
      <NgmNoticeHeaderStyle data-testid="ngm-notice-header">
        <NgmSpanStyle>
          {title && tNGM('notice_welcome_en')}
          <NgmStrongStyle>{title || tNGM('notice_welcome_default')}</NgmStrongStyle>
          {title && tNGM('notice_welcome_kr')}
        </NgmSpanStyle>
      </NgmNoticeHeaderStyle>
      <NgmNoticeContainer os={os} data-os={os}>
        <GuideFirstForNgm />
        <GuideDivider />
        <GuideSecondForNgm />
        <GuideDivider />
        <GuideThirdForNgm />
        <GuideDivider />
        <GuideFourthForNgm />
      </NgmNoticeContainer>
      <StartButton isShow={isShow} />
    </GuideWrapper>
  )
}

const GuideContainer = ({ isShow }: { isShow: boolean }) => {
  if (window.NgmLayer.isNGMDownloadService()) {
    return <GuideForNGM isShow={isShow} />
  }

  return <Guide isShow={isShow} />
}

export default GuideContainer
