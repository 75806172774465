import { ComponentChildren } from 'preact'
import styled from 'styled-components'
import NgmNoticeContainer from '@/components/guide/Guide/styled/NgmNoticeContainer.styled'
import CommonStyle from './CommonStyle.styled'

const TextBox = styled(CommonStyle)`
  font-size: 14px;
  color: #51555d;
  letter-spacing: 0.48px;
  width: 63%;
  display: flex;
  align-items: center;

  ${NgmNoticeContainer}[data-os="mac"] & {
    width: 59%;
  }
`

const FlexBox = styled(CommonStyle)`
  display: flex;
`

const NgmNoticeTextBox = ({ children }: { children: ComponentChildren }) => (
  <TextBox>
    <FlexBox>{children}</FlexBox>
  </TextBox>
)

export default NgmNoticeTextBox
