import styled from 'styled-components'

const AgreementCheckbox = styled.label`
  display: flex;
  align-items: center;

  padding: 20px 0px;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.3px;

  input {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }

  span {
    color: red;
    line-height: 22px;
  }
`

export default AgreementCheckbox
