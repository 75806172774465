import { h } from 'preact'
import { useEffect, useContext, useRef } from 'preact/hooks'
import { t } from '../../../common/i18n'
import { AppStateContext } from '../../../common/context'
import { isMac, sendLog } from '../../../common/utils'
import NgmBtnStart from './styled/NgmBtnClose.styled'

const StartButton = ({ isShow }: { isShow: boolean }) => {
  const buttonRef = useRef<HTMLButtonElement>()
  const { title } = useContext(AppStateContext)

  const startGame = () => {
    sendLog('C', 'ngmlayer_2_GameStart', title)
    if (typeof window.NGM !== 'undefined') {
      window.NGM.ExecuteNGMFromLayer()
    }
  }

  useEffect(() => {
    if (isShow && buttonRef.current) {
      const el = buttonRef.current
      const timeoutSet = setTimeout(() => {
        clearTimeout(timeoutSet)
        el.disabled = false
      }, 5 * 1000)
    }
  }, [isShow, buttonRef.current])

  return (
    <NgmBtnStart type="button" id="ngmBtnStart" ref={buttonRef} onClick={() => startGame()} aria-hidden="true" disabled>
      {t('btn_start')}
      <i />
    </NgmBtnStart>
  )
}

export default StartButton
