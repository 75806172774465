import { PLFATFORM } from './constants'

/**
 * @param  {String} action
 * @param  {String} name
 */
export const sendLog = (action: string, name: string, title: string) => {
  try {
    if (window.$h) {
      if (action === 'C') {
        window.$h.a2s.sendClickLog('P_NGM_LAYER', `{"Name":"${name}", "Value":"${title}"}`)
      } else if (action === 'V') {
        window.$h.a2s.sendContentsLog('P_NGM_LAYER', `{"Name":"${name}", "Value":"${title}"}`)
      }
    }
  } catch (e) {
    // console.error(e)
  }
}

export const getStaticURL = () => process.env.PREACT_STATIC_URL

export const getHeadFileCount = (href: string, type: string) => {
  let targetElement
  let targetAttr

  if (type === 'script') {
    targetElement = 'script'
    targetAttr = 'src'
  } else {
    targetElement = type === 'css' ? 'link' : 'none'
    targetAttr = type === 'css' ? 'href' : 'none'
  }

  let count = 0
  const tagList = document.getElementsByTagName(targetElement)
  for (let i = 0; i < tagList.length; i += 1) {
    if (tagList[i] && tagList[i].getAttribute(targetAttr) != null && tagList[i].getAttribute(targetAttr)?.indexOf(href) !== -1) {
      count += 1
    }
  }

  return count
}

export const removeHeadFile = (href: string, type: string, limit?: number) => {
  let targetElement
  let targetAttr

  if (type === 'script') {
    targetElement = 'script'
    targetAttr = 'src'
  } else {
    targetElement = type === 'css' ? 'link' : 'none'
    targetAttr = type === 'css' ? 'href' : 'none'
  }

  let removeCount = 0
  const tagList = document.getElementsByTagName(targetElement)
  for (let i = 0; i < tagList.length; i += 1) {
    if (tagList[i] && tagList[i].getAttribute(targetAttr) != null && tagList[i].getAttribute(targetAttr)?.indexOf(href) !== -1) {
      tagList[i].parentNode?.removeChild(tagList[i])
      removeCount += 1

      if (removeCount === limit) {
        return true
      }
    }
  }

  return true
}

export const setHeadFile = (href: string, type: string) => {
  let linkElement

  if (type === 'link') {
    linkElement = document.createElement('link')
    linkElement.setAttribute('href', href)
    linkElement.setAttribute('rel', 'stylesheet')
    linkElement.setAttribute('type', 'text/css')
  }

  if (linkElement) {
    document.head.appendChild(linkElement)
  }
}

export const isProd = () => process.env.PREACT_ENV === 'production'

export const getOS = () => {
  const agent = window.navigator.userAgent.toLowerCase()
  if (/(macintosh|macintel|macppc|mac68k|macos)/i.test(agent)) {
    return PLFATFORM.MAC
  }
  return PLFATFORM.WINDOW
}

export const isMac = () => {
  const os = getOS()

  return os === PLFATFORM.MAC
}

export default { sendLog, removeHeadFile, isProd }
