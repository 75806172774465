import styled from 'styled-components'
import CommonStyle from '../../../common/styled/CommonStyle.styled'

const NgmDownloadSection = styled(CommonStyle)`
  display: flex;
  padding: 15px 0;
  font-size: 14px;
  text-align: center;
  line-height: 1.57;
  letter-spacing: -0.48px;
  color: #51555d;
  border-top: 1px solid #dadee6;
`

export default NgmDownloadSection
