import { FunctionComponent, h } from 'preact'
import { useContext } from 'preact/hooks'
import { AppStateContext } from '@/common/context'
import { tNGM as t } from '../../../common/i18n'
import NgmActiveBtn from './styled/NgmActiveBtn.styled'
import NgmDownloadContainerLeft from './styled/NgmDownloadContainerLeft.styled'
import NgmDownloadContainerRight from './styled/NgmDownloadContainerRight.styled'
import NgmDownloadText from './styled/NgmDownloadText.styled'
import NgmDownloadSection from './styled/NgmWaitSection.styled'
import { PLFATFORM } from '@/common/constants'

type SetionDownloadForNgmProps = {
  showGuide: ({ target }: { target: EventTarget | null }) => void
}

const SetionDownloadForNgm: FunctionComponent<SetionDownloadForNgmProps> = ({ showGuide }) => {
  const { os } = useContext(AppStateContext)

  return (
    <NgmDownloadSection>
      <NgmDownloadContainerLeft>
        <NgmDownloadText>{t('install_desc_1')}</NgmDownloadText>
        <NgmDownloadText color={os === PLFATFORM.MAC ? '#0C77FF' : '#51555d'}>
          <span dangerouslySetInnerHTML={{ __html: t('install_desc_2') }} />
        </NgmDownloadText>
      </NgmDownloadContainerLeft>
      <NgmDownloadContainerRight>
        <NgmActiveBtn id="ngmActiveBtn" data-testid="ngm-active-button" onClick={showGuide} aria-hidden="true">
          {t('btn_install')}
        </NgmActiveBtn>
      </NgmDownloadContainerRight>
    </NgmDownloadSection>
  )
}

export default SetionDownloadForNgm
