import styled from 'styled-components'
import CommonStyle from '../../../common/styled/CommonStyle.styled'

const NgmStrongStyle = styled(CommonStyle)`
  color: #18191c;
  letter-spacing: -0.43px;
  font-size: 24px;
  font-weight: bold;
  display: inline-block;
`

export default NgmStrongStyle
