import styled from 'styled-components'
import CommonStyle from '../../styled/CommonStyle.styled'

const NgmBtnStart: any = styled.button`
  ${CommonStyle};
  width: 100%;
  background-color: black;
  color: white;
  letter-spacing: -0.48px;
  font-size: 16px;
  border-radius: 3px;
  cursor: pointer;
  padding: 12px 0;
  line-height: 1.5;
  font-weight: bold;
  margin: 24px 0;
  border: none;
  text-align: center;

  &:disabled {
    background: #dadee6 !important;
    color: #9198a5 !important;
    cursor: initial !important;

    & > i {
      border-color: #9198a5 !important;
    }
  }

  i {
    border: solid white;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 4px;
    transform: rotate(-45deg);
    margin: 8px 0 0 4px;
  }
`

export default NgmBtnStart
