import styled from 'styled-components'
import CommonStyle from '@/components/common/styled/CommonStyle.styled'

const AgreementHeaderStyle = styled(CommonStyle)`
  font-size: 24px;
  text-align: center;
  color: #51555d;
  padding-bottom: 24px;

  letter-spacing: -0.43px;
  line-height: 36px;
`

export default AgreementHeaderStyle
