import styled from 'styled-components'
import { getOS } from '@/common/utils'
import CommonStyle from '../../../common/styled/CommonStyle.styled'

const NgmNoticeContainer = styled(CommonStyle) <{ os: ReturnType<typeof getOS> }>`
  background: #f7f8fa;
  border-radius: 4px;
  width: 520px;
  min-height: 466px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  padding: ${({ os }) => (os === 'windows' ? '0 32px' : '0 13px 0 32px')};
  box-sizing: border-box;
`

export default NgmNoticeContainer
