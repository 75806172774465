/* eslint-disable no-irregular-whitespace */
/* eslint-disable max-len */
import { StateUpdater, useState } from 'preact/hooks'
import { sendLog } from '@/common/utils'
import NgmSpanStyle from '../guide/Guide/styled/NgmSpanStyle.styled'
import NgmStrongStyle from '../guide/Guide/styled/NgmStrongStyle.styled'
import AgreementButton from './styled/AgreementButton.styled'
import AgreementCheckbox from './styled/AgreementCheckbox.styled'
import AgreementContainer from './styled/AgreementContainer.styled'
import AgreementDescription from './styled/AgreementDescription.styled'
import AgreementHeaderStyle from './styled/AgreementHeader.styled'
import AgreementTextbox from './styled/AgreementTextbox.styled'
import AgreementWrapper from './styled/AgreementWrapper.styled'

type Props = {
  title: string,
  isShow: boolean
  close: StateUpdater<boolean>
}

const description = `ソフトウェア使用許諾契約

このソフトウェア使用許諾契約（以下「本契約」といいます）は、株式会社ネクソン（以下「当社」といいます）が提供するオンラインゲームおよびフィーチャーフォン、スマートフォン、タブレット等のネットワーク携帯端末用のアプリケーション等（以下「本ゲーム」といいます）のクライアントソフトウェアまたはアプリケーションソフトウェア（クライアントソフトウェアとアプリケーションソフトウェアを総称して、以下「本ソフトウェア」といいます）の使用許諾に係る条件を定めるものです。お客様が本ソフトウェアをインストールした場合には、本契約に同意したものとみなされます。
第1条（使用許諾）
当社は、お客様に対し、本契約の条項に基づき、本ソフトウェアの使用を非独占的に許諾します。
第2条（著作権）
本ソフトウェアの著作権は、当社または本ソフトウェアの開発元に帰属するものとします。
第3条（アップデート）
当社は、お客様が本ソフトウェアを実行した際に、本ソフトウェアのアップデートを行うことができるものとし、本契約は当該アップデートに対しても適用されるものとします。
第4条（禁止事項）
お客様は、次の行為をしてはならないものとします。
(1)　当社の承認なくして、第三者に対して本ソフトウェアを再頒布、貸与、または販売する行為
(2)　本ゲームの利用に必要な本数を超えて、本ソフトウェアを複製する行為
(3)　本ソフトウェアの逆コンパイル、逆アセンブル、リバースエンジニアリング等、本ソフトウェアのソースコードを解析する行為
(4)　本ソフトウェアの改変、修正、または翻案行為
(5)　本ソフトウェアの不具合、または障害を不正な目的で利用する行為
第5条（限定保証）
本ソフトウェアは現状有姿にて提供されるものであり、明示または黙示にかかわらず、当社は、本ソフトウェアがお客様の要求事項を満足させること、および本ソフトウェアにエラーがないことにつき、何等の保証もいたしません。
第6条（管轄）
本契約の準拠法は日本法とし、本契約に関連する当社とお客様との間で生じた一切の紛争は、訴額に応じ東京地方裁判所または東京簡易裁判所を第一審の専属的合意管轄裁判所とします。
第7条（雑則）
本契約に定めのない事項については、当社が別途定めるネクソン会員サービス利用規約に従うものとし、本契約と当該規約との間に矛盾がある場合は、本契約が優先するものとします。
`
const Agreement = ({ isShow, close, title }: Props) => {
  const [checked, setCheked] = useState(false)
  const onClickAgree = () => {
    // NEXONPLUG GLOBAL NJ
    if (typeof window.NGM !== 'undefined') {
      const JPPC_NEXON_PLUG_INSTALLER_DOWNLOAD_URL = window.NgmLayer.isNGMDownloadService() ? window.NGM.NGM_SETUP_URL : window.EXECUTION_LAYER_NEXONPLUG_DOWNLOAD_URL?.JPPC
      window.NGM.DownloadResource(JPPC_NEXON_PLUG_INSTALLER_DOWNLOAD_URL)
    }

    sendLog('C', 'ngmlayer_nj_terms_aggreed', title)
    close(false)
  }

  return (
    <AgreementWrapper $isShow={isShow}>
      <AgreementHeaderStyle data-testid="ngm-notice-header">
        <NgmSpanStyle>
          <NgmStrongStyle>NGM (NEXON Game Manager)</NgmStrongStyle>
          <p>ダウンロード</p>
        </NgmSpanStyle>
      </AgreementHeaderStyle>
      <AgreementContainer>
        <AgreementDescription>
          <p>本ゲームをプレイするにはNGM (NEXON Game Manager)が必要です。</p>
          <p>ソフトウェア使用許諾契約に同意のうえ、ダウンロードしてください。</p>
        </AgreementDescription>
        <AgreementTextbox type="textbox">
          <text>{description}</text>
        </AgreementTextbox>
        <AgreementCheckbox>
          <input type="checkbox" checked={checked} onChange={() => setCheked((prev) => !prev)} />
          同意する
          <span> (必須)</span>
        </AgreementCheckbox>
        <AgreementButton disabled={!checked} onClick={onClickAgree}>
          NGM ダウンロード
        </AgreementButton>
      </AgreementContainer>
    </AgreementWrapper>
  )
}

export default Agreement
