import styled from 'styled-components'
import CommonStyle from './CommonStyle.styled'

const NgmNoticeHighlight = styled(CommonStyle)`
  color: #3d4047;
  font-weight: 500;
  letter-spacing: -0.48px;
  display: inline-block;
`

export default NgmNoticeHighlight
