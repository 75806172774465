import styled from 'styled-components'
import CommonStyle from '../../common/styled/CommonStyle.styled'

const NgmNoticeText = styled(CommonStyle)`
  color: #51555d;
  letter-spacing: -0.48px;
  word-break: keep-all;
  text-align: left;
  white-space: pre-wrap;
  strong {
    font-weight: 700;
  }
`

export default NgmNoticeText
