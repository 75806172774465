import styled from 'styled-components'

const NgmInstallContent = styled.div`
  position: relative;
  width: auto;
  background-color: #fff;
  padding: 24px 24px 0;
  box-shadow: 0 3px 25px 0 rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  pointer-events: auto;

  @media (max-height: 650px) {
    align-self: flex-start;
    margin-top: 15px;
  }
`
export default NgmInstallContent
