import { FunctionComponent, h } from 'preact'
import { useContext } from 'preact/hooks'
import { tNGM as t } from '../../../common/i18n'
import NgmNoticeImgContainer from '../../common/styled/NgmNoticeImgContainer.styled'
import NgmNoticeNumbering from '../../common/styled/NgmNoticeNumbering.styled'
import NgmNoticeTextBox from '../../common/styled/NgmNoticeTextBox.styled'
import NgmNumberingWrapper from '../../common/styled/NgmNumberingWrapper.styled'
import NgmNoticeText from '../GuideFirst/NgmNoticeText.styled'
import Image from '@/components/common/Image/Image'
import { isMac } from '@/common/utils'
import NgmNoticeContainerWrapper from '@/components/common/styled/NgmNoticeContainerWrapper.styled'
import { AppStateContext } from '@/common/context'

const GuideSecondForNgm: FunctionComponent = () => {
  const { platform } = useContext(AppStateContext)
  const mac = isMac()
  const isGlobal = false

  return (
    <NgmNoticeContainerWrapper>
      <NgmNoticeTextBox>
        <NgmNumberingWrapper>
          <NgmNoticeNumbering>2</NgmNoticeNumbering>
        </NgmNumberingWrapper>
        <NgmNoticeText>
          <span dangerouslySetInnerHTML={{ __html: t('notice_desc_2_a') }} />
          <br />
          <span dangerouslySetInnerHTML={{ __html: t('notice_desc_2_b') }} />
          <span dangerouslySetInnerHTML={{ __html: t('notice_desc_2_c') }} />
        </NgmNoticeText>
      </NgmNoticeTextBox>
      <NgmNoticeImgContainer>
        <Image fileName="guide-2" width={mac ? 210 : 163} />
      </NgmNoticeImgContainer>
    </NgmNoticeContainerWrapper>
  )
}

export default GuideSecondForNgm
