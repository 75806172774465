import styled from 'styled-components'
import CommonStyle from '../../../common/styled/CommonStyle.styled'

const NgmNoticeHeaderStyle = styled(CommonStyle)`
  font-size: 24px;
  text-align: center;
  margin: 0 auto 24px auto;
  color: #51555d;
  max-width: 424px;
`

export default NgmNoticeHeaderStyle
