// @ts-nocheck
import { createGlobalStyle } from 'styled-components'

const GlobalStyledComponent = createGlobalStyle<{ $urlPrefix: string }>`
  @font-face {
      font-family: 'Noto Sans';
      src: url(${(props) => `${props.$urlPrefix}/assets/fonts/NotoSansKR-Black.woff2`}) format('woff2');
      src: url(${(props) => `${props.$urlPrefix}/assets/fonts/NotoSansKR-Black.woff`}) format('woff');
      font-weight: 900;
      font-style: normal;
      font-display: swap;
  }

  @font-face {
      font-family: 'Noto Sans KR';
      src: url(${(props) => `${props.$urlPrefix}/assets/fonts/NotoSansKR-Bold.woff2`}) format('woff2');
      src: url(${(props) => `${props.$urlPrefix}/assets/fonts/NotoSansKR-Bold.woff`}) format('woff');
      font-weight: bold;
      font-style: normal;
      font-display: swap;
  }

  @font-face {
      font-family: 'Noto Sans KR';
      src: url(${(props) => `${props.$urlPrefix}/assets/fonts/NotoSansKR-Light.woff2`}) format('woff2');
      src: url(${(props) => `${props.$urlPrefix}/assets/fonts/NotoSansKR-Light.woff`}) format('woff');
      font-weight: 300;
      font-style: normal;
      font-display: swap;
  }

  @font-face {
      font-family: 'Noto Sans KR';
      src: url(${(props) => `${props.$urlPrefix}/assets/fonts/NotoSansKR-Thin.woff2`}) format('woff2');
      src: url(${(props) => `${props.$urlPrefix}/assets/fonts/NotoSansKR-Thin.woff`}) format('woff');
      font-weight: 100;
      font-style: normal;
      font-display: swap;
  }

  @font-face {
      font-family: 'Noto Sans KR';
      src: url(${(props) => `${props.$urlPrefix}/assets/fonts/NotoSansKR-Medium.woff2`}) format('woff2');
      src: url(${(props) => `${props.$urlPrefix}/assets/fonts/NotoSansKR-Medium.woff`}) format('woff');
      font-weight: 500;
      font-style: normal;
      font-display: swap;
  }

  @font-face {
      font-family: 'Noto Sans KR';
      src: url(${(props) => `${props.$urlPrefix}/assets/fonts/NotoSansKR-Regular.woff2`}) format('woff2');
      src: url(${(props) => `${props.$urlPrefix}/assets/fonts/NotoSansKR-Regular.woff`}) format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
  }
`

const GlobalStyle = () => <GlobalStyledComponent $urlPrefix={process.env.PREACT_STATIC_URL} />
export default GlobalStyle
