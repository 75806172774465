import { FunctionComponent, h } from 'preact'
import { useContext } from 'preact/hooks'
import { t } from '../../../common/i18n'
import NgmNoticeImgContainer from '../../common/styled/NgmNoticeImgContainer.styled'
import NgmNoticeNumbering from '../../common/styled/NgmNoticeNumbering.styled'
import NgmNoticeTextBox from '../../common/styled/NgmNoticeTextBox.styled'
import NgmNoticeText from '../GuideFirst/NgmNoticeText.styled'
import Image from '@/components/common/Image/Image'
import { isMac } from '@/common/utils'
import NgmNoticeContainerWrapper from '@/components/common/styled/NgmNoticeContainerWrapper.styled'
import { AppStateContext } from '@/common/context'

const GuideFourth: FunctionComponent = () => {
  const { platform } = useContext(AppStateContext)
  const mac = isMac()
  const isGlobal = !window.NgmLayer.isNGMDownloadService()

  return (
    <NgmNoticeContainerWrapper>
      <NgmNoticeTextBox>
        <div>
          <NgmNoticeNumbering>4</NgmNoticeNumbering>
        </div>
        <NgmNoticeText>
          <span dangerouslySetInnerHTML={{ __html: t('notice_desc_4_a') }} />
          &nbsp;
          <span dangerouslySetInnerHTML={{ __html: t('notice_desc_4_b') }} />
          <span dangerouslySetInnerHTML={{ __html: t('notice_desc_4_c') }} />
        </NgmNoticeText>
      </NgmNoticeTextBox>
      <NgmNoticeImgContainer>
        <div style={{ padding: '21px 0 23px 0' }}>
          <Image fileName="guide-4" width={mac ? 210 : 169} />
        </div>
      </NgmNoticeImgContainer>
    </NgmNoticeContainerWrapper>
  )
}

export default GuideFourth
