import { FunctionComponent, h } from 'preact'
import { useContext } from 'preact/hooks'
import { t } from '../../../common/i18n'
import NgmNoticeNumbering from '../../common/styled/NgmNoticeNumbering.styled'
import NgmNoticeHighlight from '../../common/styled/NgmNoticeHighlight.styled'
import NgmNoticeImgContainer from '../../common/styled/NgmNoticeImgContainer.styled'
import NgmNoticeText from '../../common/styled/NgmNoticeText.styled'
import NgmNoticeTextBox from '../../common/styled/NgmNoticeTextBox.styled'
import NgmNumberingWrapper from '../../common/styled/NgmNumberingWrapper.styled'
import Image from '@/components/common/Image/Image'
import { isMac } from '@/common/utils'
import NgmNoticeContainerWrapper from '../../common/styled/NgmNoticeContainerWrapper.styled'
import { AppStateContext } from '@/common/context'

const GuideFirst: FunctionComponent = () => {
  const { platform } = useContext(AppStateContext)
  const mac = isMac()
  const isGlobal = !window.NgmLayer.isNGMDownloadService()
  return (
    <NgmNoticeContainerWrapper>
      <NgmNoticeTextBox>
        <NgmNumberingWrapper>
          <NgmNoticeNumbering>1</NgmNoticeNumbering>
        </NgmNumberingWrapper>
        <NgmNoticeText>
          {/* {{ platform }} */}
          <span dangerouslySetInnerHTML={{ __html: t('notice_desc_1_a') }} />
          &nbsp;
          <NgmNoticeHighlight style={{ display: 'inline' }} dangerouslySetInnerHTML={{ __html: t('notice_desc_1_b') }} />
          <span dangerouslySetInnerHTML={{ __html: t('notice_desc_1_c') }} />
        </NgmNoticeText>
      </NgmNoticeTextBox>
      <NgmNoticeImgContainer>
        <div style={{ padding: '14px 0' }}>
          <Image fileName="guide-1" width={mac ? 80 : 62} styles={{ marginTop: '10px', marginBottom: '10px' }} />
        </div>
      </NgmNoticeImgContainer>
    </NgmNoticeContainerWrapper>
  )
}

export default GuideFirst
