import { useContext } from 'preact/hooks'
import { AppStateContext } from '../../../common/context'
import { getCurrentLang } from '../../../common/i18n'
import { getStaticURL } from '../../../common/utils'
import Img from './styled/Image.styled'

type ImageProps = {
  fileName: string
  width: number
  styles?: any
}
const Image = ({
  fileName, width, styles = {},
}: ImageProps) => {
  const { os } = useContext(AppStateContext)
  const osType = !window.NgmLayer.isNGMDownloadService() ? `global-${os}` : os
  const source2x = `${getStaticURL()}/assets/imgs/${osType}/${getCurrentLang()}/${fileName}@2x.png`
  const source = `${getStaticURL()}/assets/imgs/${osType}/${getCurrentLang()}/${fileName}.png`

  const extendsStyles = { ...styles }

  return (
    <picture style={{ display: 'inline-block', ...extendsStyles }}>
      <source media="(min-width: 1080px)" srcSet={source2x} />
      <source media="(max-width: 1079px)" srcSet={source} />
      <Img src={source2x} alt={fileName} $width={width} />
    </picture>
  )
}

export default Image
