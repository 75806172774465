import Sub from './Sub/Sub'
import Main from './Main/Main'
import MainForNgm from './Main/MainForNgm'
import SubForNgm from './Sub/SubForNgm'

type GuideContentTypeProps = {
  onShowGuide: () => void
}

export const GuideContent = (props: GuideContentTypeProps) => {
  const { onShowGuide } = props
  if (window.NgmLayer.isNGMDownloadService()) {
    return (
      <>
        <MainForNgm />
        <SubForNgm showGuide={() => onShowGuide()} />
      </>
    )
  }

  return (
    <>
      <Main />
      <Sub showGuide={() => onShowGuide()} />
    </>
  )
}

export default GuideContent
