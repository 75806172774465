import styled from 'styled-components'
import CommonStyle from './CommonStyle.styled'

const NgmNoticeNumbering = styled(CommonStyle)`
  background: #0c77ff;
  border-radius: 2px;
  width: 20px;
  font-size: 14px;
  font-weight: 400;
  color: white;
  margin-right: 8px;
  text-align: center;
  height: 20px;
  box-sizing: border-box;
`

export default NgmNoticeNumbering
