import styled from 'styled-components'
import CommonStyle from '../../../common/styled/CommonStyle.styled'

const NgmSpanStyle = styled(CommonStyle)`
  display: block;
  white-space: normal;
  color: #51555d;
`

export default NgmSpanStyle
