import styled from 'styled-components'
import CommonStyle from '../../../common/styled/CommonStyle.styled'

const NgmGameTitle = styled(CommonStyle)`
  color: #18191c;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.5;
  text-align: center;
`

export default NgmGameTitle
